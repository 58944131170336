var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "sidebar-invoice-add-new-customer",
      "sidebar-class": "sidebar-lg",
      "bg-variant": "white",
      shadow: "",
      backdrop: "",
      "no-header": "",
      right: ""
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
              },
              [
                _c("h5", { staticClass: "mb-0" }, [_vm._v(" Add Customer ")]),
                _c("feather-icon", {
                  staticClass: "ml-1 cursor-pointer",
                  attrs: { icon: "XIcon", size: "16" },
                  on: { click: hide }
                })
              ],
              1
            ),
            _c(
              "b-form",
              {
                staticClass: "p-2",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: "Customer Name",
                      "label-for": "customer-name"
                    }
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "customer-name",
                        trim: "",
                        placeholder: "John Doe"
                      },
                      model: {
                        value: _vm.customer.name,
                        callback: function($$v) {
                          _vm.$set(_vm.customer, "name", $$v)
                        },
                        expression: "customer.name"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  { attrs: { label: "Email", "label-for": "email" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "email",
                        trim: "",
                        placeholder: "example@domain.com",
                        type: "email"
                      },
                      model: {
                        value: _vm.customer.email,
                        callback: function($$v) {
                          _vm.$set(_vm.customer, "email", $$v)
                        },
                        expression: "customer.email"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  { attrs: { label: "Address", "label-for": "address" } },
                  [
                    _c("b-form-textarea", {
                      attrs: {
                        id: "address",
                        placeholder: "221B Baker Street",
                        trim: ""
                      },
                      model: {
                        value: _vm.customer.message,
                        callback: function($$v) {
                          _vm.$set(_vm.customer, "message", $$v)
                        },
                        expression: "customer.message"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  { attrs: { label: "Country", "label-for": "country" } },
                  [
                    _c("v-select", {
                      attrs: {
                        dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                        options: _vm.countries,
                        clearable: false,
                        "input-id": "country"
                      },
                      model: {
                        value: _vm.customer.country,
                        callback: function($$v) {
                          _vm.$set(_vm.customer, "country", $$v)
                        },
                        expression: "customer.country"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  { attrs: { label: "Contact", "label-for": "contact" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "contact",
                        type: "number",
                        placeholder: "763-242-9206",
                        trim: ""
                      },
                      model: {
                        value: _vm.customer.contact,
                        callback: function($$v) {
                          _vm.$set(_vm.customer, "contact", $$v)
                        },
                        expression: "customer.contact"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex mt-2" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple.400",
                            value: "rgba(255, 255, 255, 0.15)",
                            expression: "'rgba(255, 255, 255, 0.15)'",
                            modifiers: { "400": true }
                          }
                        ],
                        staticClass: "mr-2",
                        attrs: { variant: "primary", type: "submit" },
                        on: { click: hide }
                      },
                      [_vm._v(" Add ")]
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple.400",
                            value: "rgba(186, 191, 199, 0.15)",
                            expression: "'rgba(186, 191, 199, 0.15)'",
                            modifiers: { "400": true }
                          }
                        ],
                        attrs: { variant: "outline-secondary" },
                        on: { click: hide }
                      },
                      [_vm._v(" Cancel ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }